var numeral = require('numeral')

export const prettifyFileSize = (bytes, type=['value', 'symbol'][0]) => {
    if ( bytes == null || bytes == undefined ) return { value: '---', symbol: '' }[type]
    if ( bytes == 0 ) return { value: '0', symbol: 'KB' }[type]
    if ( bytes <= 1024 ) return { value: '~1', symbol: 'KB' }[type]
    const kb = bytes / 1024
    if ( kb <= 1024 ) return { value: numeral(kb).format('0.[0]'), symbol: 'KB' }[type]
    const mb = kb / 1024
    if ( mb <= 1024 ) return { value: numeral(mb).format('0.[00]'), symbol: 'MB' }[type]
    const gb = mb / 1024
    if ( gb <= 1000 ) return { value: numeral(gb).format('0.[00]'), symbol: 'GB' }[type]
    return { value: '>1000', symbol: 'GB' }[type]
}
