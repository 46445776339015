<template>
    <div>
        <v-simple-table>
            <thead>
                <tr>
                    <th>Video Codec</th>
                    <th>Dimensions</th>
                    <th>Video Bitrate</th>
                    <th>File Size</th>
                    <th>Frame Rate</th>
                    <!-- <th>File Size Reduction</th> -->
                </tr>
            </thead>
            <tbody>
                <tr class="text-left">
                    <td class="text-uppercase">{{ metadata.video_codec }}</td>
                    <td>
                        <span>{{ metadata.width }} x {{ metadata.height }}</span>
                        <span class="space caption">px</span>
                    </td>
                    <td>
                        <span>{{ metadata.video_bitrate_in_kbps }}</span>
                        <span class="space caption">kbps</span>
                    </td>
                    <td class="d-flex align-center">
                        <span>
                            <span>{{ metadata.file_size_bytes | prettifyFileSize }}</span>
                            <span class="space caption">{{ metadata.file_size_bytes | prettifyFileSize('symbol') }}</span>
                        </span>
                        <v-chip class="ml-2 px-2 py-2" :color="sizeSmaller ? 'green' : 'red'" x-small dark>
                            <span class="caption">{{ sizeSmaller ? '-' : '+' }}</span>
                            <span class="space caption">{{ Math.abs(metadata.optimized_size_percent) }}</span>
                            <span class="space caption">%</span>
                        </v-chip>
                    </td>
                    <td>
                        <span>{{ metadata.frame_rate }}</span>
                        <span class="space caption">fps</span>
                    </td>
                    <!-- <td :class="metadata.optimized_size_percent < 0 ? 'red--text' : 'green--text'">
                        <span >{{ metadata.optimized_size_percent }}</span>
                        <span class="space caption">%</span>
                    </td> -->
                </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
import { prettifyFileSize } from '@/filters'

export default {
    name: "OptimizeMediaMetadata",
    props: {
        metadata: {
            type: Object,
            required: true
        }
    },    
    filters: {
        prettifyFileSize
    },
    computed: {
        sizeSmaller () {
            return this.metadata.optimized_size_percent >= 0
        }
    }
}
</script>

<style scoped>
.space {
    padding-left: 1px;
}
</style>